import React from "react";
import Confetti from "react-confetti";

import stjude from "../../../../img/sj_logo.png";
// import elizabeth from "../../../img/elizabeth.jpg";
// import jacob from "../../../img/jacob.jpg";
// import langston from "../../../img/langston.jpg";
// import raven from "../../../img/raven.jpg";

const Fundraiser = (props) => {
  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  return (
    <div className="fundraiser">
      <div className="fundraiser_wrapper">
        <img src={stjude} alt="St. Jude" className="sj_logo" />
        <div className="fundraiser_info">
          <Confetti
            width={1200}
            height={730}
            numberOfPieces={50}
            gravity={0.1}
            // gravity={0.04}
            colors={[
              "#8332a7",
              "#1874dc",
              "#64d0e4",
              "#c0da07",
              "#ffc32c",
              "#490e67",
              "#002856",
              "#1eadc1",
              "#75bc21",
              "#ffb125",
              "#c10f3a",
            ]}
          />
          <div className="username">
            <span>{props.username}</span>
          </div>
          <div className="amount_raised">
            <span>${formatNumber(props.amount_raised)}</span>
            <p>Lifetime Raised For St. Jude Children's Research Hospital</p>
          </div>
        </div>
      </div>
      {/* <div className="fundraiser__patients">
            <div className="elizabeth">
              <img src={elizabeth} alt="elizabeth" />
              <span className="fundraiser__patients__info">
                St. Jude patient Elizabeth
              </span>
            </div>
            <div className="jacob">
              <img src={jacob} alt="jacob" />
              <span className="fundraiser__patients__info">
                St. Jude patient Jacob
              </span>
            </div>
            <div className="langston">
              <img src={langston} alt="langston" />
              <span className="fundraiser__patients__info">
                St. Jude patient Langston
              </span>
            </div>
            <div className="raven">
              <img src={raven} alt="raven" />
              <span className="fundraiser__patients__info">
                St. Jude patient Raven
              </span>
            </div>
          </div> */}
    </div>
  );
};

export default Fundraiser;
