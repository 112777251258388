import React, { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import KioskBase from "./components/TotalRaised/KioskBase";
import PublicBase from "./components/TotalRaised/PublicBase";

function App() {
  return (
    <Fragment>
      <Router>
        <Switch>
          <Route exact path="/total-raised-kiosk/">
            <KioskBase />
          </Route>
          <Route exact path="/total-raised/">
            <PublicBase />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
