import React from "react";

import PublicMain from "./Public/PublicMain/PublicMain";

const Base = (props) => {
  return (
    <div className="total-raised">
      <PublicMain />
    </div>
  );
};

export default Base;
