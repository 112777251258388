import React from "react";

import Main from "../TotalRaised/Kiosk/Main/Main";

const Base = (props) => {
  return (
    <div className="total-raised">
      <Main />
    </div>
  );
};

export default Base;
