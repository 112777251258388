import React, { useState, useEffect, useRef } from "react";
import Confetti from "react-confetti";

import stjude from "../../../../img/sj_logo.png";
// import elizabeth from "../../../img/elizabeth.jpg";
// import jacob from "../../../img/jacob.jpg";
// import langston from "../../../img/langston.jpg";
// import raven from "../../../img/raven.jpg";

const Fundraiser = (props) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setHeight(refHeight.current.clientHeight);
    setWidth(refWidth.current.clientWidth);
  }, []);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const refHeight = useRef(null);
  const refWidth = useRef(null);

  const gheight = height;
  const gwidth = width;
  return (
    <div className="public">
      <div ref={refWidth} className="fundraiser">
        <div className="fundraiser_wrapper">
          <img src={stjude} alt="St. Jude" className="sj_logo" />
          <div ref={refHeight} className="fundraiser_info">
            <Confetti
              width={gwidth}
              height={gheight}
              numberOfPieces={50}
              // gravity={0.1}
              gravity={0.04}
              colors={[
                "#8332a7",
                "#1874dc",
                "#64d0e4",
                "#c0da07",
                "#ffc32c",
                "#490e67",
                "#002856",
                "#1eadc1",
                "#75bc21",
                "#ffb125",
                "#c10f3a",
              ]}
            />
            <div className="username">
              <span>{props.username}</span>
            </div>
            <div className="amount_raised">
              {/* <span>${formatNumber(props.amount_raised)}</span> */}

              <span>
                {props.amount_raised - Math.floor(props.amount_raised) !== 0
                  ? formatter.format(props.amount_raised)
                  : "$" + formatNumber(props.amount_raised)}
              </span>
              <p>Lifetime Raised For St. Jude Children's Research Hospital</p>
            </div>
          </div>
        </div>
        {/* <div className="fundraiser__patients">
            <div className="elizabeth">
              <img src={elizabeth} alt="elizabeth" />
              <span className="fundraiser__patients__info">
                St. Jude patient Elizabeth
              </span>
            </div>
            <div className="jacob">
              <img src={jacob} alt="jacob" />
              <span className="fundraiser__patients__info">
                St. Jude patient Jacob
              </span>
            </div>
            <div className="langston">
              <img src={langston} alt="langston" />
              <span className="fundraiser__patients__info">
                St. Jude patient Langston
              </span>
            </div>
            <div className="raven">
              <img src={raven} alt="raven" />
              <span className="fundraiser__patients__info">
                St. Jude patient Raven
              </span>
            </div>
          </div> */}
      </div>
    </div>
  );
};

export default Fundraiser;
